import { useContext, createContext, useState } from 'react'
import pandaCelebrateAnimation from 'public/lotties/panda-celebrate.json'
import pandaWaveAnimation from 'public/lotties/panda-wave.json'
import { random, sample } from 'lodash'
import { useGetUserQuery } from '@/redux/features/api/apiSlice'

const CELEBRATE_ANIMATIONS = { celebrate: pandaCelebrateAnimation, wave: pandaWaveAnimation }
const CELEBRATE_KEYS = Object.keys(CELEBRATE_ANIMATIONS)
type CelebrateKey = keyof typeof CELEBRATE_ANIMATIONS
type WhichPanda = CelebrateKey | null
export interface ShowPandaProps {
    panda: WhichPanda,
    pandaAnimation: any | null,
    setPanda: ((item: WhichPanda | ((item: WhichPanda) => WhichPanda)) => void),
    showPanda: (duration?: number) => void,
    chanceShowPanda: (chance?: number) => void,
}

export const PandaContext = createContext<ShowPandaProps>({ panda: null, pandaAnimation: null, showPanda: () => { }, setPanda: () => { }, chanceShowPanda: () => { } })

export function useGlobalPanda() {
    return useContext(PandaContext)
}

export function useShowPanda() {
    const [item, setItem] = useState<CelebrateKey | null>(null)
    const pandaAnimation = item && CELEBRATE_ANIMATIONS[item] || null
    const { data: user } = useGetUserQuery()

    const showPanda = (duration = 7) => {
        if (user?.disableAnimations) return
        const key = sample(CELEBRATE_KEYS) as CelebrateKey
        setItem(key)
        setTimeout(() => {
            setItem(null)
        }, duration * 1000)
    }

    const props: ShowPandaProps = {
        panda: item,
        setPanda: setItem,
        pandaAnimation,
        showPanda,
        chanceShowPanda: (chance = 3, duration?: number) => {
            if (random(1, chance) !== 1) return
            showPanda(duration)
        }
    }
    return props
}